import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RensnerSvg from "../components/rensnerSvg"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <section className="w-full h-screen grid" style={{ maxHeight: 900 }}>
        <StaticImage
          className="object-cover w-full h-full"
          src="../images/rensner-start-bg.png"
          alt="Dateilaufnahme eines Ölgemäldes"
          style={{ gridArea: "1/1" }}
          placeholder="blurred"
        />
        <div
          className="object-cover w-full h-full  relative top-0"
          style={{ gridArea: "1/1", paddingTop: 0 }}
        >
          <div className="container mx-auto flex flex-row justify-end">
            <RensnerSvg className="mr-8 mt-8 lg:w-1/2 w-3/4" />
            <h1 className="screen-reader hidden">rensner</h1>
          </div>
        </div>
      </section>
      <section
        id="gallerie"
        className="container mx-auto px-2 my-12 grid md:grid-cols-6"
      >
        <div>
          <h2 className="md:text-8xl text-6xl font-bold break-words mb-4">
            gallerie
          </h2>
        </div>
        <div className="col-span-5">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div key={node.id} className="grid grid-cols-2 gap-8 mb-10">
              <div className="flex flex-row justify-end">
                {node.frontmatter.image && (
                  <GatsbyImage
                    image={
                      node.frontmatter.image.childImageSharp.gatsbyImageData
                    }
                  />
                )}
              </div>
              {console.log(node)}
              <div className="relative">
                <h3 className="md:text-6xl text-3xl font-bold">
                  {node.frontmatter.title}
                </h3>
                <p className="md:text-6xl text-3xl font-bold">
                  {node.frontmatter.date}
                </p>
                <div
                  dangerouslySetInnerHTML={{ __html: node.frontmatter.excerpt }}
                ></div>
                <span className="absolute bottom-0 opacity-50">
                  {node.frontmatter.sold ? "verkauft." : ""}
                </span>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export const Indexquery = graphql`
  query MyQuery {
    allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "gallery" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            excerpt
            date(formatString: "YYYY")
            sold
            image {
              childImageSharp {
                gatsbyImageData(height: 400)
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
